
import { Options, Vue } from "vue-class-component";
import { mapActions, mapGetters } from "vuex";
import EmptyBasket from "./EmptyBasket.vue";
import { BasketItemToApi } from "../interfaces/Basket";
import {
  DeliveryTypeId,
  DeliveryTypesForComponent,
  Order,
  SendOrderToApiResult,
  ValidatorResult,
} from "../interfaces/Order";

@Options({
  components: { EmptyBasket },
  computed: {
    ...mapGetters("basket", ["basketCost", "basketForOrder"]),
    ...mapGetters("order", [
      "deliveryTypes",
      "deliveryTypeDefaultInView",
      "deliveryTimesWithTemplate",
      "deliveryCost",
      "orderCost",
      "validateOrderData",
    ]),
  },
  methods: mapActions("order", ["sendOrderToApi"]),
})
export default class OrderingForm extends Vue {
  basketCost!: number;

  basketForOrder!: BasketItemToApi[];

  deliveryTypes!: DeliveryTypesForComponent;

  deliveryTypeDefaultInView!: DeliveryTypeId;

  deliveryTimesWithTemplate!: (
    typeId: DeliveryTypeId
  ) => Array<{ time: [Date, Date]; template: string }>;

  deliveryCost!: (deliveryId: DeliveryTypeId) => number;

  orderCost!: (deliveryId: DeliveryTypeId) => number;

  validateOrderData!: (orderData: Order) => ValidatorResult;

  sendOrderToApi!: (orderData: Order) => Promise<SendOrderToApiResult>;

  orderData: Order = {
    deliveryType: 0,
    deliveryTime: 0,
    clientName: "",
    clientPhone: "",
    address: "",
    comment: "",
    positions: [],
  };

  deliveryTimes: Array<{ time: [Date, Date]; template: string }> = [];

  clientAddress = [
    {
      order: 1,
      active: true,
      id: "addr-city",
      title: "Населённый пункт",
      placeholder: "",
      value: "",
    },
    {
      order: 2,
      active: true,
      id: "addr-street",
      title: "Улица",
      placeholder: "",
      value: "",
    },
    {
      order: 3,
      active: true,
      id: "addr-house",
      title: "Дом",
      placeholder: "",
      value: "",
    },
    {
      order: 4,
      active: true,
      id: "addr-flat",
      title: "Квартира",
      placeholder: "",
      value: "",
    },
  ];

  alert = {
    isActive: false,
    message: "",
  };

  changeDeliveryType(ind: DeliveryTypeId | string): void {
    this.orderData.deliveryType = +ind;
    this.deliveryTimes = this.deliveryTimesWithTemplate(this.orderData.deliveryType);
    this.orderData.deliveryTime = this.deliveryTimes[0].time[0].getTime();
  }

  async sendOrder(): Promise<void> {
    this.saveClientDataToLocalStorage();

    const orderAdjustedData: Order = { ...this.orderData };
    orderAdjustedData.address = this.clientAddress
      .filter((el) => el.value.length)
      .map((el) => `${el.title} ${el.value}`)
      .join("; ");
    orderAdjustedData.positions = this.basketForOrder;

    const validatorResult = this.validateOrderData(orderAdjustedData);

    if (!validatorResult.result) {
      this.alert.message = validatorResult.error;
      this.alert.isActive = true;
      return;
    }

    const resultFromApi: SendOrderToApiResult = await this.sendOrderToApi(orderAdjustedData);

    if (!resultFromApi.result) {
      this.alert.message = resultFromApi.error;
      this.alert.isActive = true;
    }
  }

  getClientDataFromLocalStorage(): void {
    const savedClientName = localStorage.getItem("clientName");
    if (savedClientName) {
      this.orderData.clientName = savedClientName;
    }
    const savedClientPhone = localStorage.getItem("clientPhone");
    if (savedClientPhone) {
      this.orderData.clientPhone = savedClientPhone;
    }
  }

  saveClientDataToLocalStorage(): void {
    try {
      localStorage.setItem("clientName", this.orderData.clientName);
      localStorage.setItem("clientPhone", this.orderData.clientPhone);
    } catch (error) {
      // TODO: if the storage is full
      // https://developer.mozilla.org/en-US/docs/Web/API/Storage/setItem#exceptions
    }
  }

  created(): void {
    this.changeDeliveryType(this.deliveryTypeDefaultInView);
    this.getClientDataFromLocalStorage();
  }
}
