
import { Options, Vue } from "vue-class-component";
import { mapGetters, mapState } from "vuex";
import EmptyBasket from "../components/EmptyBasket.vue";
import OrderingForm from "../components/OrderingForm.vue";
import { OrderAnswerFromApi } from "../interfaces/Order";

@Options({
  components: { EmptyBasket, OrderingForm },
  computed: {
    ...mapState("order", ["sendingOrderNow", "receivedOrderDataFromApi"]),
    ...mapGetters("basket", ["basketCost"]),
  },
})
export default class Ordering extends Vue {
  sendingOrderNow!: boolean;

  receivedOrderDataFromApi!: OrderAnswerFromApi;

  basketCost!: number;

  createdTimeToString(): string {
    const createdTime: number = this.receivedOrderDataFromApi.createdAt;
    return new Date(createdTime).toLocaleTimeString();
  }

  deliveryTimeToString(): string {
    const time: number = this.receivedOrderDataFromApi.deliveryTime;
    return new Date(time).toLocaleString();
  }
}
